import React from 'react';
import PropTypes from 'prop-types';

import { unexisty } from '@dop/shared/helpers/functional';
import {
	isExternalLink,
	isProtocolWhitelisted,
	parseUrl,
} from '@dop/shared/helpers/link';
import { IconEmail } from '@dop/ui-icons/svg/email';

import IsExternal from '../isExternal';
import { DOPLink } from '@/uiComposites/interactive/DOPLink';

const MailtoIcon = () => {
	return (
		<span
			aria-hidden="true"
			css={`
				position: relative;
				display: inline-block;
				height: 1rem;
				padding-right: 2px;
			`}
		>
			<IconEmail
				aria-label="Mailto link"
				css={`
					height: 1.3rem;
					width: 1.3rem;
				`}
			/>
		</span>
	);
};

export const ExternalLinkIcon = ({
	linkTo,
	className,
	checkExternal = true,
}) => {
	if (unexisty(linkTo)) {
		return null;
	}

	if (isExternalLink(linkTo)) {
		return <IsExternal isExternal={checkExternal} className={className} />;
	}
	return null;
};
ExternalLinkIcon.propTypes = {
	linkTo: PropTypes.string.isRequired,
	className: PropTypes.string,
	checkExternal: PropTypes.bool,
};

const Link_ = (
	{
		checkExternal,
		to: linkTo,
		linkType,
		children,
		onClick,
		title,
		['aria-label']: ariaLabel,
		['aria-hidden']: ariaHidden,
		newWindow,
		hideMailtoIcon,
		...props
	},
	ref,
) => {
	// isProtocolWhitelisted prevents javascript in href
	if (unexisty(linkTo) || !isProtocolWhitelisted(linkTo)) {
		return null;
	}

	const isMailtoLink = parseUrl(linkTo).protocol === 'mailto:';

	const maybeNewWindowProps =
		newWindow || isMailtoLink
			? {
					target: '_blank',
					rel: 'noopener noreferrer',
				}
			: {};
	return (
		<DOPLink
			ref={ref}
			{...maybeNewWindowProps}
			title={newWindow ? title || 'open in new tab' : title}
			aria-label={ariaLabel}
			aria-hidden={ariaHidden}
			data-link-type={linkType}
			{...props}
			onClick={onClick}
			href={linkTo}
		>
			{isMailtoLink && !hideMailtoIcon && <MailtoIcon />}
			{children}
			{!isMailtoLink && (
				<ExternalLinkIcon linkTo={linkTo} checkExternal={checkExternal} />
			)}
		</DOPLink>
	);
};

export const Link = React.forwardRef(Link_);
Link.propTypes = {
	to: PropTypes.string,
	children: PropTypes.node,
	checkExternal: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseOver: PropTypes.func,
	className: PropTypes.string,
	id: PropTypes.string,
	title: PropTypes.string,
	['aria-label']: PropTypes.string,
	['aria-hidden']: PropTypes.bool,
	tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	itemProp: PropTypes.string,
	newWin: PropTypes.bool,
	hideMailtoIcon: PropTypes.bool,
};
